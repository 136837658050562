<template>
  <div v-for="(item, index) in multipleFormData" :key="index">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-4">Form {{ index + 1 }}</h3>
      <div class="d-flex justify-content-between align-items-center gap-2">
        <button
          class="btn w-sm btn-primary waves-effect waves-light px-2"
          @click="copyNewForm(index)"
        >
          Copy
        </button>
        <button
          class="btn w-sm btn-success waves-effect waves-light px-2"
          @click="addnewForm()"
        >
          Add
        </button>
        <button
          @click="deleteForm(index)"
          v-if="index > 0"
          class="btn w-sm btn-danger waves-effect waves-light px-2"
        >
          Delete
        </button>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-lg-3">
        <label for="name">
          Name
          <span class="text-danger">*</span>
        </label>
      </div>

      <div class="col-lg-6">
        <input
          name="name"
          id="name"
          type="text"
          class="form-control"
          v-model="item.name"
          @input="(event) => handleChange('Name', index, event)"
        />
        <div class="text-danger" v-if="item.error?.name_error">
        {{ item.error?.name_error }}
      </div>
      </div>
      
    </div>

    <div class="row mb-3">
      <div class="col-lg-3">
        <label for="remark">
          Lowest Score
          <span class="text-danger">*</span>
        </label>
      </div>

      <div class="col-lg-6">
        <input
          name="lowest_score"
          id="lowest_score"
          type="number"
          min="1"
          max="5"
          v-model="item.lowest_score"
          class="form-control"
          @input="(event) => handleChange('Lowest Score', index, event)"
        />
        <div class="text-danger" v-if="item.error?.lowest_score_error">
          {{ item.error?.lowest_score_error }}
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-lg-3">
        <label for="remark">
          Highest Score
          <span class="text-danger">*</span>
        </label>
      </div>

      <div class="col-lg-6">
        <input
          name="highest_score"
          id="highest_score"
          type="number"
          v-model="item.highest_score"
          min="1"
          max="5"
          class="form-control"
          @input="(event) => handleChange('Highest Score', index, event)"
        />
        <div class="text-danger" v-if="item.error?.highest_score_error">
          {{ item.error?.highest_score_error }}
        </div>
      </div>
    </div>

    <hr />
  </div>

  <div class="row">
    <div class="col-md-6 mx-auto">
      <div class="mt-3 mb-3 ms-3 text-center">
        <router-link :to="{ name: 'trainer-trainee-criteria' }">
          <button type="button" class="btn w-sm btn-secondary me-5">
            Cancel
          </button>
        </router-link>

        <button
          :disabled="isLoading ? true : false"
          type="button"
          class="btn w-sm btn-success waves-effect waves-light px-3"
          @click="store()"
        >
          <span
            v-if="isLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>

          {{ isLoading == true ? "Loading..." : "Save" }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import { levels } from "../../../const";
import { types } from "../../../const";

import validationErrors from "../../../mixins/validationErrors";

export default {

  setup() {
    const toast = useToast();

    return { toast };
  },

  components: {
    vSelect,
  },

  mixins: [validationErrors],

  data() {
    return {
      multipleFormData: [
        {
          name: "",
          lowest_score: "",
          highest_score: "",
          error: {
            name_error: "",
            lowest_score_error: "",
            highest_score_error: "",
          },
        },
      ],
      isLoading: false,
      allowStore: true,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },

  methods: {
    addnewForm() {
      this.multipleFormData.push({
        name: "",
        lowest_score: "",
        highest_score: "",
        error: {
          name_error: "",
          lowest_score_error: "",
          highest_score_error: "",
        },
      });
    },

    copyNewForm(index) {
      const copiedObject = JSON.parse(JSON.stringify(this.multipleFormData[index]));
      this.multipleFormData.push(copiedObject);
    },

    deleteForm(index) {
      this.multipleFormData.splice(index, 1);
    },

    selectedProgram(options, index) {
      return !this.multipleFormData[index]?.program?.length
        ? true
        : this.multipleFormData[index].program.find(
            (p) => p.id == options.id
          ) === undefined;
    },

    handleChange(type, index, event) {
      if (type == "Name") {
        this.multipleFormData[index].name = event.target.value;
        if (!this.multipleFormData[index]?.name) {
          this.multipleFormData[index].error.name_error = "Name is required.";
        } else {
          this.multipleFormData[index].error.name_error = "";
        }
      } else if (type == "Lowest Score") {
        const value = Number(event.target.value);
        if (value >= 1 && value <= 5) {
          this.multipleFormData[index].lowest_score = value;
        } else {
          this.multipleFormData[index].lowest_score = null
        }

        if (!this.multipleFormData[index]?.lowest_score) {
          this.multipleFormData[index].error.lowest_score_error = "Lowest Score is required.";
        } else {
          this.multipleFormData[index].error.lowest_score_error = "";
        }
      } else if (type == "Highest Score") {
        const value = Number(event.target.value);
        if (value >= 1 && value <= 5) {
          this.multipleFormData[index].highest_score = value;
        } else {
          this.multipleFormData[index].highest_score = null
        }
        if (!this.multipleFormData[index]?.highest_score) {
          this.multipleFormData[index].error.highest_score_error = "Highest Score is required.";
        } else {
          this.multipleFormData[index].error.highest_score_error = "";
        }
      }
    },

    checkBeforeStore() {
      this.allowStore = true;
      this.multipleFormData.forEach((item) => {
        if (!item.name)
          (item.error.name_error = "Name is required."),
            (this.allowStore = false);
        if (!item.lowest_score)
          (item.error.lowest_score_error = "Lowest Score is required."),
            (this.allowStore = false);
        if (!item.highest_score)
          (item.error.highest_score_error = "Highest Score is required."),
            (this.allowStore = false);
      });
    },

    async store() {
      this.checkBeforeStore();
      if (this.allowStore) {

        const formData = [];

        this.multipleFormData.forEach((item) => {
          this.isLoading = true;

          let data = {
            name: item.name,
            lowest_score: item.lowest_score ?? 0,
            highest_score: item.highest_score ?? 0,
          };

          formData.push(data);
        });

        await axios
          .post(
            `${this.baseUrl}admin/v2/trainee-to-trainer-criteria-multiple`,
            formData
          )

          .then(() => {
            this.$router.push({ name: "trainer-trainee-criteria" });
            this.toast.success("Successfully Created Trainee To Trainer Criteria!");
          })
          .catch(() => this.toast.error("Something Went Wrong!"));

        this.isLoading = false;
      } else {
        console.log("Not allowed to store,");
      }
    },
  },

  onMounted() {
    this.multipleFormData = [];
    this.isLoading=false;
    this.allowStore=true;
  }
};
</script>
